<template>
  <info-card
    :caption="`${ validation.results.length } result(s) shown`"
    :items="validation.results"
    :loading="validation.isInProgress"
    :initial-state="initialState"
    :is-valid-validation="isValidValidation"
    loading-label="Validating configuration. Please wait ..."
  >
    <template #removeAllAction>
      <mi-btn
        v-if="validation.results.length"
        data-testid="remove-all-btn"
        flat
        @click="openDeleteChoiceDialog"
      >
        Remove all
      </mi-btn>
    </template>
    <!-- Validation results item -->
    <template #item="{ item }">
      <mi-list-item
        :key="item.id"
        class="validation-item bg-white"
        dense
        :clickable="!optionsAreLoading"
        @click="!isDeleteChoiceDialogShown ? $emit('click:validation-item', { ...item }) : ''"
      >
        <!-- Content -->
        <mi-list-item-section>
          <mi-tooltip max-width="330px">{{ item.choiceName }}</mi-tooltip>
          <mi-list-item-label>
            {{ item.optionId2 }} - {{ item.choiceId2 }} - {{ getTruncatedString(item.choiceName) }}
          </mi-list-item-label>
        </mi-list-item-section>

        <!-- Delete button -->
        <mi-list-item-section side>
          <mi-btn
            class="validation-item__remove-btn"
            icon="close"
            icon-size="12px"
            icon-type
            dense
            fab
            flat
            @click="openDeleteChoiceDialog(item)"
          ></mi-btn>
        </mi-list-item-section>
      </mi-list-item>
    </template>

    <!-- Validate button -->
    <template #actions>
      <mi-btn color="accent" @click="handleValidateCurrentConfiguration"> Validate </mi-btn>
    </template>
  </info-card>

  <!-- Delete invalid choice dialog -->
  <delete-choice-dialog
    v-model="isDeleteChoiceDialogShown"
    :choice="choiceToUnselect"
    :remove-all-choices="removeAllChoices"
    @unselect:choice="handleChoiceUnselectEvent"
  ></delete-choice-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import notify from '@/utils/notify'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { PAC_VALIDATION_REQUESTED, PAC_UNSELECT_VALIDATION } from '@/utils/analytics/constants'
  import { poll } from '@/api/polling'
  import { CHANGED_CONFIGURATION, SET_VALIDATION } from '@/store/modules/pac/mutationTypes'
  import InfoCard from '@/components/pac/configuration/info/ConfigurationInfoCard.vue'
  import DeleteChoiceDialog from './ConfigurationInfoValidationUnselectDialog.vue'

  const { mapActions, mapGetters, mapMutations, mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationInfoValidation',
    components: {
      DeleteChoiceDialog,
      InfoCard
    },
    emits: ['click:validation-item'],
    data: () => ({
      isDeleteChoiceDialogShown: false,
      choiceToUnselect: {},
      clearPoll: null,
      removeAllChoices: true
    }),
    computed: {
      ...mapState(['validation', 'isValidValidation', 'initialState']),
      ...mapGetters(['optionsAreLoading'])
    },
    beforeUnmount() {
      this.clearPoll?.()
    },
    methods: {
      ...mapActions(['validateCurrentConfigurationV2']),
      ...mapMutations({ SET_VALIDATION, CHANGED_CONFIGURATION }),

      getTruncatedString(string) {
        return string.split('', 30)
          .reduce((o, c) => (o.length === 29 ? `${ o }${ c }...` : `${ o }${ c }`), '')
      },
      handleChoiceUnselectEvent() {
        this.choiceToUnselect = {}
        this.removeAllChoices = true

        // Analytics
        recordAnalytics(PAC_UNSELECT_VALIDATION)

        if (this.validation.results?.length) return

        this.SET_VALIDATION({ showBadge: false, initialState: true })
      },
      openDeleteChoiceDialog({ optionId = '', choiceId = '', choiceId2 = '' } = {}) {
        if (optionId && choiceId) {
          this.choiceToUnselect = { optionId, choiceId, choiceId2 }
          this.removeAllChoices = false
        }
        this.isDeleteChoiceDialogShown = true
      },
      async getValidateConfiguration() {
        const { executePoll, clearPoll } = poll()
        this.clearPoll = clearPoll
        return this.validateCurrentConfigurationV2(executePoll)
      },
      async handleValidateCurrentConfiguration() {
        const result = await this.getValidateConfiguration()

        this.CHANGED_CONFIGURATION()

        if (result?.status !== 'error') return

        const errorMessage = result.errorMessage !== 'Async request failed' ? result.errorMessage
          : 'An unexpected error occurred when validating the configuration.'
        notify({
          title: 'Validation error',
          content: errorMessage,
          type: 'negative'
        })
        recordAnalytics(PAC_VALIDATION_REQUESTED)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .validation-item__remove-btn.mi-btn.q-btn--fab.q-btn--dense {
    padding: .25rem;
    margin-right: -.25rem;
  }
</style>
